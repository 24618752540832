const component = document.querySelector('.c-distraction-tool');
if (component) {
  var allActivities = [
    // Please keep in alphabetical order
    'Arrange a video get-together with friends',
    'Bake a cake',
    'Call or video call someone',
    'Cook and eat a delicious meal',
    'Dance to your favourite song',
    'Daydream',
    'Declutter your wardrobe',
    "Do 10 minutes of deep breathing. Try our <a href='https://tools.wearewithyou.org.uk/deepbreathing/'>free online deep breathing tool</a>",
    'Do a puzzle or crossword',
    "Do an online exercise class. Watch <a href='https://www.nhs.uk/conditions/nhs-fitness-studio/'>a series of exercise videos</a> from the NHS Fitness Studio.",
    'Do artwork or a craft',
    'Do odd jobs around your home',
    'Do some gardening',
    'Do the dishes',
    'Floss your teeth',
    'Give yourself a manicure or pedicure',
    "Learn to say 10 words in another language. Try <a href='https://www.google.com/search?q=duolingo&oq=duolin&aqs=chrome.1.69i57j0l4j69i60l3.2824j0j7&sourceid=chrome&ie=UTF-8'>free online language lessons</a>",
    'Listen to a podcast or radio show',
    'Listen to a relaxation recording',
    'Listen to your favourite music',
    'Look after your houseplants',
    'Look at pictures of beautiful landscapes online',
    'Meditate',
    'Plan future trips or holidays',
    'Plant seeds in a windowsill pot',
    'Play a board or card game',
    'Play with your pet',
    'Read a book or magazine',
    'Read a religious or philosophical book',
    'Read cartoons or comics',
    'Read the newspaper',
    'Rearrange a room',
    'Sew or do some needlework',
    'Sing or play an instrument',
    'Sit by the fire',
    'Solve a problem you’ve been putting off',
    'Start a new project',
    'Start a scrapbook or photo album',
    'Surf the internet',
    'Take a long bath or a shower',
    'Talk about old times',
    'Watch a film',
    'Watch a funny film or TV show',
    'Wear your favourite outfit or item of clothing',
    'Write a diary',
    'Write a letter',
    'Write a story, poem or song',
    'Write down 3 things you are grateful for'
  ];

  var activityList = component.querySelector(
      '.c-distraction-tool__activity-list'
    ),
    prevButton = component.querySelector('.c-distraction-tool__show-previous'),
    nextButton = component.querySelector('.c-distraction-tool__show-next'),
    randomActivity = component.querySelector(
      '.c-distraction-tool__random-activity'
    );

  allActivities.forEach(function(item) {
    var newListItem = document.createElement('li');
    newListItem.className = 'c-distraction-tool__activity-list-item';
    newListItem.innerHTML = item;
    activityList.appendChild(newListItem);
  });

  var shuffledActivities = shuffle(allActivities);
  var visibleIndex = -1; // -1 = not started yet
  var allSeen = false; // true = can loop back around

  function shuffle(array) {
    var ctr = array.length,
      temp,
      index;

    while (ctr > 0) {
      index = Math.floor(Math.random() * ctr);
      ctr--;
      temp = array[ctr];
      array[ctr] = array[index];
      array[index] = temp;
    }
    return array;
  }

  function fadeTextInOut() {
    setTimeout(function() {
      randomActivity.classList.add(
        'c-distraction-tool__random-activity--faded-out'
      );
    }, 0);
    setTimeout(function() {
      randomActivity.innerHTML = shuffledActivities[visibleIndex];
      randomActivity.classList.remove(
        'c-distraction-tool__random-activity--faded-out'
      );
    }, 500);
  }

  function showPrevious() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'distraction',
        eventAction: 'click-prev'
      });
    }
    visibleIndex--;
    if (visibleIndex == 0 && !allSeen) {
      prevButton.disabled = true;
    } else if (visibleIndex < 0) {
      visibleIndex = shuffledActivities.length - 1;
    }
    fadeTextInOut();
  }

  function showNext() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'distraction',
        eventAction: 'click-next'
      });
    }
    visibleIndex++;
    if (visibleIndex == shuffledActivities.length) {
      visibleIndex = 0;
      allSeen = true;
    }
    fadeTextInOut();
    if (visibleIndex > 0) {
      prevButton.disabled = false;
    }
  }

  prevButton.addEventListener('click', function() {
    showPrevious();
  });

  nextButton.addEventListener('click', function() {
    randomActivity.classList.remove(
      'c-distraction-tool__random-activity--inactive'
    );
    showNext();
  });
}
