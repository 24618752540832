import AuditC, { RISK_LEVELS } from './drinkscheckin/auditc';
import OtherQuestions from './drinkscheckin/other-questions';
import Templates from './drinkscheckin/templates';
import Suggestions from './drinkscheckin/suggestions';
import formTemplate from '../../drinkscheckin/questionform.njk';

const component = document.querySelector('.c-drinkscheckin-tool');
if (component) {
  const PAGES = {
    INTRO: 0,
    AUDIT_C_1: 1,
    AUDIT_C_2: 2,
    AUDIT_C_3: 3,
    LIFESTYLE_DRIVE: 4,
    LIFESTYLE_PUB: 5,
    HEALTH_SLEEP: 6,
    HEALTH_DEPRESSED: 7,
    HEALTH_MEMORY: 8,
    HEALTH_MEDS: 9,
    HEALTH_CONDITIONS: 10,
    RESULTS_TEMPLATE: 11
  };
  const AUDIT_C_PAGES = [PAGES.AUDIT_C_1, PAGES.AUDIT_C_2, PAGES.AUDIT_C_3];
  const OTHER_QS_PAGES = [
    PAGES.LIFESTYLE_DRIVE,
    PAGES.LIFESTYLE_PUB,
    PAGES.HEALTH_SLEEP,
    PAGES.HEALTH_DEPRESSED,
    PAGES.HEALTH_MEMORY,
    PAGES.HEALTH_MEDS,
    PAGES.HEALTH_CONDITIONS
  ];

  let currentPage = PAGES.INTRO;

  let selectedTemplate = '';

  const introPage = component.querySelector('[data-section-intro]');
  const auditCSection = component.querySelector('[data-section-auditc]');
  const auditCForm = component.querySelector('[data-auditc-form]');
  const otherQuestionsSection = component.querySelector(
    '[data-section-other-questions]'
  );
  const otherQuestionsForm = component.querySelector(
    '[data-other-questions-form]'
  );
  const resultsTemplate = component.querySelector('[data-section-results]');
  const suggestedChangesSection = resultsTemplate.querySelector(
    '[data-suggested-changes]'
  );
  const moreAdviceSection = resultsTemplate.querySelector('[data-more-advice]');

  const auditC = new AuditC();
  const otherQuestions = new OtherQuestions();
  const templates = new Templates();
  const suggestions = new Suggestions();

  // page navigation and display

  function goToPage(page) {
    currentPage = page;
    history.pushState({ currentPage: page }, null, null);
    displayPage(page);
  }

  function goBack() {
    currentPage -= 1;
    history.pushState({ currentPage: currentPage }, null, null);
    displayPage(currentPage);
  }

  function displayPage(page) {
    component.querySelectorAll('.c-drinkscheckin-tool__page').forEach(p => {
      hideElement(p);
    });
    if (page == PAGES.INTRO) {
      showElement(introPage);
    } else if (AUDIT_C_PAGES.indexOf(page) !== -1) {
      renderForm(page);
      const unitInfoBox = component.querySelector(
        '.c-drinkscheckin-tool__auditc-unit-info'
      );
      if (page == PAGES.AUDIT_C_2 || page == PAGES.AUDIT_C_3) {
        showElement(unitInfoBox);
      } else {
        hideElement(unitInfoBox);
      }
      showElement(auditCSection);
    } else if (OTHER_QS_PAGES.indexOf(page) !== -1) {
      renderForm(page);
      showElement(otherQuestionsSection);
    } else if (page == PAGES.RESULTS_TEMPLATE) {
      showElement(resultsTemplate);
    }
    jump('top');
    document.activeElement.blur();
  }

  function hideElement(c) {
    c.classList.add('u-is-hidden');
  }

  function showElement(c) {
    c.classList.remove('u-is-hidden');
  }

  function jump(h) {
    const loc = component.querySelector(`.${h}`).offsetTop;
    window.scrollTo(0, loc);
  }

  // form and custom content rendering

  function renderForm(page) {
    let form = '';
    let prefix = '';
    let questionSrc = '';
    let questionNum = '';
    let question = '';

    if (AUDIT_C_PAGES.indexOf(page) !== -1) {
      form = auditCForm;
      prefix = 'auditc';
      questionSrc = auditC;
      questionNum = page - 1;
    } else if (OTHER_QS_PAGES.indexOf(page) !== -1) {
      form = otherQuestionsForm;
      prefix = 'other';
      questionSrc = otherQuestions;
      questionNum = page - 4;
    }
    question = questionSrc.questions[questionNum];

    if (!question) {
      console.log(`no question for index, page: ${questionNum},${page}`);
      return;
    }

    if (typeof ga !== 'undefined') {
      let toolPage = 'drinkscheckin/' + page;
      toolPage =
        toolPage +
        '-' +
        question.label
          .replace(/\?+/g, '')
          .replace(/\W+/g, '-')
          .toLowerCase();
      ga('set', 'page', toolPage);
      ga('send', 'pageview');
    }

    form.innerHTML = formTemplate.render({
      question: question,
      prefix: prefix
    });
    if (
      question.options.filter(function(e) {
        return e.detail;
      }).length > 0
    ) {
      const radios = form.getElementsByTagName('input');
      for (var i = radios.length; i--; ) {
        radios[i].onclick = function() {
          if (this.value == 1) {
            showElement(
              form.querySelector('.c-drinkscheckin-tool__radio-detail')
            );
          } else {
            hideElement(
              form.querySelector('.c-drinkscheckin-tool__radio-detail')
            );
          }
        };
      }
    }
  }

  function findInArray(arr, callback) {
    for (let i = 0; i < arr.length; i++) {
      let match = callback(arr[i]);
      if (match) {
        return arr[i];
      }
    }
  }

  function renderTemplate(level) {
    if (typeof ga !== 'undefined') {
      let toolPage = 'drinkscheckin/results';
      ga('set', 'page', toolPage);
      ga('send', 'pageview');
    }

    const selectedTemplate = findInArray(templates.versions, function(t) {
      return t.level == level;
    });

    const resultsHeader = resultsTemplate.querySelector(
      '[data-results-header]'
    );
    const resultsSummary = resultsTemplate.querySelector(
      '[data-results-summary]'
    );
    const riskIcon = resultsTemplate.querySelector(
      '.c-drinkscheckin-tool__results-page-risk-icon'
    );
    const drinkingComparison = resultsTemplate.querySelector(
      '.c-drinkscheckin-tool__results-page-drinking-comparison'
    );

    resultsHeader.innerHTML = selectedTemplate.header;
    resultsSummary.innerHTML = selectedTemplate.detail;
    riskIcon.src = selectedTemplate.icon;

    if (level != 'POSSIBLE_DEPENDENCE') {
      if (level == 'HIGH' || level == 'INCREASING') {
        drinkingComparison.querySelector('img').src =
          selectedTemplate.compare_icon;
        drinkingComparison.querySelector('p').innerHTML =
          selectedTemplate.compare_text;
        showElement(drinkingComparison);
      }
      renderSuggestedChanges();
      showElement(suggestedChangesSection);
      const moreAdviceItems = renderMoreAdvice();
      if (moreAdviceItems > 0) {
        showElement(moreAdviceSection);
      } else {
        hideElement(moreAdviceSection);
      }
    } else {
      hideElement(suggestedChangesSection);
      hideElement(moreAdviceSection);
    }
  }

  function renderSuggestedChanges() {
    const suggestion = suggestions.suggestions[suggestions.currentlyShown];
    suggestedChangesSection.querySelector('.suggestion-label').innerHTML =
      suggestion.label;
    suggestedChangesSection.querySelector('.suggestion-detail').innerHTML =
      suggestion.detail;

    suggestedChangesSection.querySelector(
      '.c-drinkscheckin-tool__new-suggestion-button'
    ).onclick = function(event) {
      event.preventDefault();
      suggestions.nextSuggestion();
      renderSuggestedChanges();
    };
  }

  function renderMoreAdvice() {
    let numberOfItems = 0;
    moreAdviceSection.querySelector('.advice-list').innerHTML += `
      ${otherQuestions.questions
        .map(o => {
          if (o.selected == 1) {
            if (o.relatedAdviceContent) {
              numberOfItems++;
              return `
              <div class="advice-content-row">
              <div class="advice-content-icon"><img src="/img/check-circle.svg"></div>
              <div class="advice-content-text">
              ${o.relatedAdviceContent}
              </div>
              </div>`;
            } else return '';
          }
        })
        .join('')}
    `;
    return numberOfItems;
  }

  // form handling

  function handleAuditCSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const checked = form.querySelector('input[type=radio]:checked');
    if (!checked) {
      showError(form);
      return;
    }
    cancelError(form);
    // first page of audit-C (0 in array) is at page 1 of drinks tool
    auditC.answerQuestion(currentPage - 1, checked.value);

    if (auditC.isComplete) {
      const riskLevel = auditC.calculateRisk();
      if (riskLevel === RISK_LEVELS.POSSIBLE_DEPENDENCE) {
        renderTemplate('POSSIBLE_DEPENDENCE');
        goToPage(PAGES.RESULTS_TEMPLATE);
      } else {
        if (riskLevel === RISK_LEVELS.LOW) {
          selectedTemplate = 'LOW';
        } else if (riskLevel === RISK_LEVELS.INCREASING) {
          selectedTemplate = 'INCREASING';
        } else if (riskLevel === RISK_LEVELS.HIGH) {
          selectedTemplate = 'HIGH';
        }
        goToPage(currentPage + 1);
      }
    } else {
      goToPage(currentPage + 1);
    }
  }

  function handleOtherQuestionsFormSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const checked = form.querySelector('input[type=radio]:checked');
    if (!checked) {
      showError(form);
      return;
    }
    cancelError(form);
    // first page of other questions (0 in array) is at page 4 of drinks tool
    otherQuestions.answerQuestion(currentPage - 4, checked.value);
    if (currentPage + 1 == PAGES.RESULTS_TEMPLATE) {
      renderTemplate(selectedTemplate);
      goToPage(currentPage + 1);
    } else {
      goToPage(currentPage + 1);
    }
  }

  // error handling

  function showError(c) {
    c.querySelector('fieldset').classList.add('o-form-field--error');
  }

  function cancelError(c) {
    c.querySelector('fieldset').classList.remove('o-form-field--error');
  }

  // listeners

  component
    .querySelector('#start-button')
    .addEventListener('click', function(event) {
      event.preventDefault();
      goToPage(currentPage + 1);
      this.blur();
    });

  const backButtons = component.querySelectorAll(
    '.c-drinkscheckin-tool__back-button'
  );
  for (var i = backButtons.length; i--; ) {
    backButtons[i].onclick = function(event) {
      event.preventDefault();
      goToPage(currentPage - 1);
      this.blur();
    };
  }

  auditCForm.addEventListener('submit', handleAuditCSubmit);
  otherQuestionsForm.addEventListener('submit', handleOtherQuestionsFormSubmit);

  window.onload = function() {
    history.replaceState({ currentPage }, null, null);
  };

  window.addEventListener('popstate', e => {
    if (e.state !== null) {
      currentPage = e.state.currentPage;
      displayPage(currentPage);
    } else displayPage(null);
  });
}
