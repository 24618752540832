export default class OtherQuestions {
  constructor() {
    this.questions = [
      {
        label: 'Do you drive?',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              "As you get older, it takes longer for your body to process alcohol. This can put you over the drink-drive limit without realising, even the next day. It's safest not to drink any alcohol the night before you drive."
          },
          { label: 'No', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'Leave plenty of time to recover from drinking before you drive - a whole day if possible.'
      },
      {
        label: 'Are you most likely to drink at home or at the pub?',
        options: [
          {
            label: "At home; either mine or someone else's",
            value: 1,
            detail:
              'When you drink at home, it’s easy to lose track of how much you’ve had. Try to measure each drink when you pour it - and don’t top it up until it’s finished.'
          },
          { label: 'At the pub', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'When drinking at home, measure your drinks carefully and don’t top them up until they’re finished.'
      },
      {
        label:
          'Do you have any sleep problems, like difficulty falling asleep, waking too early or waking up in the night?',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              'Alcohol can make sleep problems worse, even if it feels like it helps you get to sleep sometimes.'
          },
          { label: 'No', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'Find out more about <a href="https://www.wearewithyou.org.uk/help-and-advice/advice-you/how-alcohol-affects-your-sleep/">how alcohol affects your sleep</a>.'
      },
      {
        label: 'Do you ever feel depressed?',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              'Alcohol causes chemical changes in your brain that can make depression worse. If you’ve experienced depression or low mood, reducing how much you drink could help.'
          },
          { label: 'No', value: 0 }
        ],

        selected: null,
        relatedAdviceContent:
          'Find out more about <a href="https://www.wearewithyou.org.uk">how alcohol and depression are linked</a>.'
      },
      {
        label: 'Do you ever have trouble remembering things?',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              'Alcohol affects your memory and the impact increases as you get older. Reducing the amount your drink can help. But speak to your GP if memory problems affect your day-to-day life.'
          },
          { label: 'No', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'Speak to your GP if memory problems are affecting your day-to-day life'
      },
      {
        label: 'Do you take any medicines at least three times a week?',
        sub:
          'This includes non-prescription medicine like hay fever tablets, paracetamol and ibuprofen',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              'Alcohol can stop some medicines from working properly, and can sometimes cause dangerous side-effects. Check the leaflet that came with your medicine, or speak to your GP or pharmacist for advice.'
          },
          { label: 'No', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'Check that your medicines are safe to take with alcohol'
      },
      {
        label:
          'Has your doctor told you that you have any ongoing health conditions?',
        sub:
          'This includes things like high blood pressure, diabetes or liver conditions like hepatitis',
        options: [
          {
            label: 'Yes',
            value: 1,
            detail:
              'Alcohol can make many health conditions worse. It’s important to let your doctor know how much you drink so they can give you the best advice and treatment.'
          },
          { label: 'No', value: 0 }
        ],
        selected: null,
        relatedAdviceContent:
          'Ask your doctor how alcohol could affect your long-term conditions'
      }
    ];
  }

  answerQuestion(index, value) {
    const question = this.questions[index];
    if (question) {
      question.selected = parseInt(value);
    } else {
      console.log('no question at index ', index);
    }
  }
}
