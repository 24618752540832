const component = document.querySelector('.c-deep-breathing-tool');
if (component) {
  // Each animation stage is 4 seconds
  const animationStageLength = 4000;
  // We want to delay the start by 1 second
  const animationStartDelay = 1000;

  // We want to run through animation stages in this sequence
  const animationStages = [breathIn, holdBreath, breathOut, holdBreath];

  // To track the current animation stage and timeout/interval
  let currentAnimationStage = null;
  let animationIntervalID = null;
  let animationTimeoutID = null;

  let animationStartedAt = null;

  const inner = component.querySelector('.c-deep-breathing-tool__inner-circle');
  const message = component.querySelector('.c-deep-breathing-tool__message');
  const btn = component.querySelector(
    '.c-deep-breathing-tool__animation-toggle'
  );
  const originalMessage = message.innerHTML;

  function fadeMessage(text) {
    message.classList.add('c-deep-breathing-tool__message--fade');
    message.innerHTML = text;
  }

  function breathIn() {
    inner.classList.remove('c-deep-breathing-tool__inner-circle--breathe-out');
    fadeMessage('Breathe in slowly');
    inner.classList.add('c-deep-breathing-tool__inner-circle--breathe-in');
  }

  function breathOut() {
    inner.classList.remove('c-deep-breathing-tool__inner-circle--breathe-in');
    fadeMessage('Breathe out slowly');
    inner.classList.add('c-deep-breathing-tool__inner-circle--breathe-out');
  }

  function holdBreath() {
    fadeMessage('Now hold your breath');
  }

  btn.addEventListener('click', handleStart);

  function handleStart() {
    message.innerHTML = '';
    btn.innerHTML = 'Stop';
    btn.removeEventListener('click', handleStart);

    recordStart();

    btn.addEventListener('click', handleStop);

    // start animation after `animationStartDelay` hold
    animationTimeoutID = setTimeout(startAnimation, animationStartDelay);
  }

  function startAnimation() {
    // reset current animation stage and call it
    currentAnimationStage = 0;
    animationStages[currentAnimationStage].call();

    // call `nextAnimationStage` every 4 seconds
    animationIntervalID = setInterval(nextAnimationStage, animationStageLength);
  }

  function nextAnimationStage() {
    currentAnimationStage += 1;
    if (currentAnimationStage >= animationStages.length) {
      currentAnimationStage = 0;
    }
    animationStages[currentAnimationStage].call();
  }

  function handleStop() {
    // clear any active interval/timeout
    clearTimeout(animationTimeoutID);
    clearInterval(animationIntervalID);

    recordStop();

    btn.innerHTML = 'Start breathing exercise';
    btn.removeEventListener('click', handleStop);
    btn.addEventListener('click', handleStart);

    inner.className = 'c-deep-breathing-tool__inner-circle';
    message.className = 'c-deep-breathing-tool__message';
    message.innerHTML = originalMessage;
  }

  function recordStart() {
    // track when we start so we can determine duration upon end
    animationStartedAt = new Date();

    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'square-breathing',
        eventAction: 'start-exercise'
      });
    }
  }

  function recordStop() {
    if (!animationStartedAt) {
      return;
    }

    // Find time elapsed in seconds and clear start date
    const animationEndedAt = new Date();
    const timeElapsed = Math.round(
      (animationEndedAt - animationStartedAt) / 1000
    );
    animationStartedAt = null;

    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'square-breathing',
        eventAction: 'stop-exercise',
        eventValue: timeElapsed.toString()
      });
    }
  }
}
