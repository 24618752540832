export const RISK_LEVELS = {
  POSSIBLE_DEPENDENCE: 4,
  HIGH: 3,
  INCREASING: 2,
  LOW: 1,
  UNKNOWN: 0
};

export default class AuditC {
  constructor() {
    this.questions = [
      {
        label: 'How often do you have an alcoholic drink?',
        options: [
          { label: 'Never', value: 0 },
          { label: 'Monthly or less', value: 1 },
          { label: '2 to 4 times a month', value: 2 },
          { label: '2 to 3 times a week', value: 3 },
          { label: '4 or more times a week', value: 4 }
        ],
        selected: null
      },
      {
        label: 'How many units of alcohol do you normally have when you drink?',
        sub: 'Think about a typical drinking day in the last year',
        options: [
          { label: '1 or 2 units', value: 0 },
          { label: '3 or 4 units', value: 1 },
          { label: '5 or 6 units', value: 2 },
          { label: '7 to 9 units', value: 3 },
          { label: '10 or more units', value: 4 }
        ],
        selected: null
      },
      {
        label:
          'How often have you had 6 or more units on one occasion in the past year?',
        options: [
          { label: 'Never', value: 0 },
          { label: 'Less than monthly', value: 1 },
          { label: 'Monthly', value: 2 },
          { label: 'Weekly', value: 3 },
          { label: 'Daily or almost daily', value: 4 }
        ],
        selected: null
      }
    ];
  }

  get isComplete() {
    return this.questions.every(question => question.selected != null);
  }

  answerQuestion(index, value) {
    const question = this.questions[index];
    if (question) {
      question.selected = parseInt(value);
    } else {
    }
  }

  calculateRisk() {
    if (!this.isComplete) {
      return RISK_LEVELS.UNKNOWN;
    }

    const totalScore = this.questions.reduce(
      (accumulator, question) => accumulator + question.selected,
      0
    );

    if (totalScore >= 0 && totalScore <= 4) {
      return RISK_LEVELS.LOW;
    } else if (totalScore >= 5 && totalScore <= 7) {
      return RISK_LEVELS.INCREASING;
    } else if (totalScore >= 8 && totalScore <= 10) {
      return RISK_LEVELS.HIGH;
    } else if (totalScore >= 11) {
      return RISK_LEVELS.POSSIBLE_DEPENDENCE;
    }
  }
}
