import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import 'promise-polyfill/src/polyfill';

const component = document.querySelector('.c-smartgoals-tool');

if (component) {
  const categories = {
    big: {
      suggestions: [
        'Exercise more',
        'Eat more healthily',
        'Reduce my drinking or drug use',
        'Stop drinking or using drugs altogether',
        'Have a better relationship with my friends or family'
      ]
    },
    small: {
      suggestions: [
        'Have a conversation with a friend',
        'Join or attend a support group',
        'Start keeping a journal',
        'Have an extra drink-free or drug-free day',
        'Make an appointment with my doctor',
        'Walk for half an hour',
        'Find a new distraction for next time I have cravings',
        'Throw away my drug - related items',
        'Cook a new meal',
        'Practice a relaxation exercise',
        'Make a list of people who can support me'
      ]
    }
  };

  const addGoalPage = component.querySelector(
      '.c-smartgoals-tool__add-goal-page'
    ),
    displayGoalPage = component.querySelector(
      '.c-smartgoals-tool__display-goal-page'
    ),
    bigGoalInputEntered = component.querySelector('#goal-big-input'),
    smallGoalInputEntered = component.querySelector('#goal-small-input'),
    imageArea = component.querySelector('#img-area'),
    form = component.querySelector('#goal-submit-form');
  const saveImageButton = component.querySelector(
    '.c-smartgoals-tool__save-image'
  );
  let imageCanvas;
  let fileSaveSupported = false;

  try {
    fileSaveSupported = !!new Blob();
  } catch (e) {}

  if (fileSaveSupported) {
    saveImageButton.classList.remove('u-is-hidden');
  }

  // Listeners
  const handleSubmitForm = function(event) {
    event.preventDefault();
    var bigGoalInputArea = component.querySelector(
        '.c-smartgoals-tool__goal-big-input'
      ),
      smallGoalInputArea = component.querySelector(
        '.c-smartgoals-tool__goal-small-input'
      );
    var errors = false;
    if (bigGoalInputEntered.value == '') {
      showError(bigGoalInputArea);
      errors = true;
    }
    if (smallGoalInputEntered.value == '') {
      showError(smallGoalInputArea);
      errors = true;
    }
    if (errors == false) {
      const bigGoal = bigGoalInputEntered.value.toLowerCase();
      const smallGoal = smallGoalInputEntered.value.toLowerCase();
      if (typeof ga !== 'undefined') {
        ga('send', 'event', {
          eventCategory: 'goals',
          eventAction: 'set-goal',
          eventLabel: `GOAL: ${bigGoal} ACTION: ${smallGoal}`
        });
      }
      component.querySelector(
        '.c-smartgoals-tool__big-goal'
      ).innerHTML = bigGoal;
      component.querySelector(
        '.c-smartgoals-tool__first-step'
      ).innerHTML = smallGoal;
      goToDisplayGoalPage();
      hideError(bigGoalInputArea);
      hideError(smallGoalInputArea);

      var img = new Image();
      html2canvas(component.querySelector('#capture')).then(canvas => {
        img.alt = 'my goal';
        img.src = canvas.toDataURL();
        imageCanvas = canvas;

        imageArea.appendChild(img);
        component
          .querySelector('.c-smartgoals-tool__goal-frame')
          .classList.add('u-is-hidden');
      });
      saveImageButton.addEventListener('click', handleClickSaveImage);
    }
  };

  const handleClickEditGoal = function(event) {
    event.preventDefault();
    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'goals',
        eventAction: 'edit-goal'
      });
    }
    displayGoalPage.classList.add('u-is-hidden');
    imageArea.removeChild(imageArea.firstChild);
    component
      .querySelector('.c-smartgoals-tool__goal-frame')
      .classList.remove('u-is-hidden');
    addGoalPage.classList.remove('u-is-hidden');
    window.scroll(0, 0);
  };

  const handleClickSaveImage = function(event) {
    event.preventDefault();

    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'goals',
        eventAction: 'save-image'
      });
    }

    imageCanvas.toBlob(blob => {
      FileSaver.saveAs(blob, 'my-goal.png');
    });
  };

  function goToDisplayGoalPage() {
    addGoalPage.classList.add('u-is-hidden');
    displayGoalPage.classList.remove('u-is-hidden');
    window.scroll(0, 0);
  }

  form.addEventListener('submit', handleSubmitForm);

  component
    .querySelector('.c-smartgoals-tool__edit-goal')
    .addEventListener('click', handleClickEditGoal);

  component
    .querySelector('.suggest-button--goal-big')
    .addEventListener('click', function() {
      showModal('big');
    });
  component
    .querySelector('.suggest-button--goal-small')
    .addEventListener('click', function() {
      showModal('small');
    });

  // modal handling

  function showModal(topic) {
    //topic is big or small
    var suggestionDropdownArea = component.querySelector(
      '.goal-suggestions-box'
    );

    var suggestions, targetInput;

    if (topic == 'big') {
      component.querySelector('.topic').innerHTML = 'Your big ambition:';
      component.querySelector('.goal-suggestion-submit-button').innerHTML =
        'Add big ambition';
    } else {
      component.querySelector('.topic').innerHTML = 'Next I will:';
      component.querySelector('.goal-suggestion-submit-button').innerHTML =
        'Add this goal';
    }

    suggestions = categories[topic].suggestions;
    targetInput = `goal-${topic}-input`;

    var suggestionSelection = component.querySelector(
      '.goal-suggestions-select'
    );
    // initialise dropdown and populate from suggestions
    suggestionSelection.innerHTML =
      "<option selected value=''>Please choose a suggestion...</option>";
    suggestions.forEach(function(suggestion) {
      var suggestionOption = document.createElement('option');
      var suggestionText = document.createTextNode(suggestion);
      suggestionOption.appendChild(suggestionText);
      suggestionSelection.appendChild(suggestionOption);
    });

    var addButton = component.querySelector('.goal-suggestion-submit-button');
    addButton.onclick = function addButtonClick() {
      var goalSuggestionsBox = component.querySelector('.goal-suggestions-box');
      var input;
      var inputSelect = component.querySelector('.goal-suggestions-select');
      input = inputSelect.options[inputSelect.selectedIndex];
      if (input.value == '') {
        showError(goalSuggestionsBox);
      }

      if (input.value != '') {
        component.querySelector(`#${targetInput}`).value = input.value;
        // hide error message on form page if displayed
        hideError(
          component.querySelector(`.c-smartgoals-tool__${targetInput}`)
        );
        hideModal();
      }
    };

    // show modal
    component
      .querySelector('.goal-suggestions-modal')
      .classList.remove('c-modal-overlay--hidden');
  }

  function hideModal() {
    // hide any existing error messages
    hideError(component.querySelector('.goal-suggestions-box'));
    component
      .querySelector('.goal-suggestions-modal')
      .classList.add('c-modal-overlay--hidden');
  }

  component
    .querySelector('.goal-suggestion-cancel-button')
    .addEventListener('click', hideModal);

  // error handling

  function showError(c) {
    c.classList.add('o-form-field--error');
  }

  function hideError(c) {
    c.classList.remove('o-form-field--error');
  }
}
