const component = document.querySelector('.c-prosandcons-tool');
if (component) {
  const categories = {
    good: {
      suggestions: [
        'Drinking helps me socialise',
        'Drinking helps me sleep',
        'Drinking helps me relax',
        'Drinking takes my mind off my worries',
        'I like the feeling of being drunk'
      ]
    },
    bad: {
      suggestions: [
        'Drinking costs a lot of money',
        'Drinking is affecting my sleep',
        'Drinking is bad for my health',
        'I get hangovers after drinking',
        'I get into arguments or fights after drinking'
      ]
    }
  };

  var countGoodThings = 0,
    countBadThings = 0,
    totalWeightGood = 0,
    totalWeightBad = 0;

  const introPage = component.querySelector('.intro-page'),
    listsPage = component.querySelector('.lists-page'),
    summaryPage = component.querySelector('.summary-page');

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function scrollToLists() {
    component
      .querySelector('.summary-lists')
      .scrollIntoView({ behavior: 'smooth' });
  }

  function goToListsPage() {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory: 'prosandcons',
        eventAction: 'click-start'
      });
    }

    // hide intro page (page 1) and show list building page (page 2)
    hideElement(introPage);
    showElement(listsPage);
    window.scroll(0, 0);
  }

  function addItemToList(targetList, item, weight) {
    if (targetList == 'good-things-list') {
      countGoodThings++;
      totalWeightGood += weight;
    } else if (targetList == 'bad-things-list') {
      countBadThings++;
      totalWeightBad += weight;
    }

    var newListRow = document.createElement('div');
    newListRow.className = 'list-row';

    newListRow.dataset.weight = weight;

    var newRowItemDiv = document.createElement('div'),
      newRowItemText = document.createTextNode(item);
    newRowItemDiv.className = 'list-row-item';
    newRowItemDiv.appendChild(newRowItemText);

    var newDeleteButtonDiv = document.createElement('div'),
      newDeleteButton = document.createElement('span'),
      newDeleteButtonText = document.createTextNode('Delete');
    newDeleteButton.className = 'c-button--simple delete-button';
    newDeleteButton.appendChild(newDeleteButtonText);
    newDeleteButtonDiv.appendChild(newDeleteButton);

    newListRow.appendChild(newRowItemDiv);
    newListRow.appendChild(newDeleteButtonDiv);
    component.querySelector(`.${targetList}-content`).appendChild(newListRow);
    showElement(component.querySelector(`.${targetList}`));
  }

  component
    .querySelectorAll('.good-things-list, .bad-things-list')
    .forEach(function(list) {
      list.addEventListener('click', function(event) {
        if (event.target.classList.contains('delete-button')) {
          deleteItemFromList(event.target);
        }
      });
    });

  function deleteItemFromList(c) {
    var parentListContent = c.closest(
      '.good-things-list-content,.bad-things-list-content'
    );
    var thisRow = c.closest('.list-row');
    var weight = thisRow.dataset.weight;

    if (parentListContent.classList.contains('good-things-list-content')) {
      countGoodThings--;
      totalWeightGood -= weight;
      if (typeof ga !== 'undefined') {
        ga('send', 'event', {
          eventCategory: 'prosandcons',
          eventAction: 'click-delete-good'
        });
      }
    } else if (
      parentListContent.classList.contains('bad-things-list-content')
    ) {
      countBadThings--;
      totalWeightBad -= weight;
      if (typeof ga !== 'undefined') {
        ga('send', 'event', {
          eventCategory: 'prosandcons',
          eventAction: 'click-delete-bad'
        });
      }
    }

    parentListContent.removeChild(thisRow);

    if (countGoodThings == 0) {
      hideElement(component.querySelector('.good-things-list'));
    }
    if (countBadThings == 0) {
      hideElement(component.querySelector('.bad-things-list'));
    }
  }

  function goToSummaryPage() {
    // hide lists page (page 2) and show summary page (page 3)
    var goodThingsListArea = component.querySelector(
        '.lists-page-good-things-list-area'
      ),
      badThingsListArea = component.querySelector(
        '.lists-page-bad-things-list-area'
      );
    if (countGoodThings == 0 || countBadThings == 0) {
      if (countGoodThings == 0) {
        showError(goodThingsListArea);
      }
      if (countBadThings == 0) {
        showError(badThingsListArea);
      }
    } else {
      hideElement(listsPage);
      setUpSummaryPage();
      showElement(summaryPage);
      window.scroll(0, 0);

      if (typeof ga !== 'undefined') {
        ga('send', 'event', {
          eventCategory: 'prosandcons',
          eventAction: 'click-continue-to-summary'
        });
      }
    }
  }

  function setUpSummaryPage() {
    var goodList = component.querySelector('.good-things-list-content'),
      badList = component.querySelector('.bad-things-list-content'),
      finalGoodList = component.querySelector(
        '.final-good-things-list-content'
      ),
      finalBadList = component.querySelector('.final-bad-things-list-content');

    // initialise all possible header, content elements to hidden (in case we're coming back here and refreshing)
    var allSummaryElements = component.querySelectorAll(
      '.summary-header, .summary-content, .importance-text'
    );
    allSummaryElements.forEach(summaryElement => hideElement(summaryElement));

    // initialise or reset (if refreshing) summary page lists to blank
    finalGoodList.innerHTML = '';
    finalBadList.innerHTML = '';

    // text underneath good/bad thing counts which sums up overall importance
    if (totalWeightGood > totalWeightBad) {
      showElement(component.querySelector('.importance-text-1'));
    } else if (totalWeightGood < totalWeightBad) {
      showElement(component.querySelector('.importance-text-2'));
    } else if (totalWeightGood == totalWeightBad) {
      showElement(component.querySelector('.importance-text-3'));
    }

    // select which header and content elements to display based on counts and weights
    var toDisplay;
    if (countGoodThings > countBadThings) {
      showElement(component.querySelector('.summary-header-more-good'));
      if (totalWeightGood > totalWeightBad) {
        toDisplay = component.querySelector('.count-good-weight-good');
      } else if (totalWeightBad > totalWeightGood) {
        toDisplay = component.querySelector('.count-good-weight-bad');
      } else if (totalWeightGood == totalWeightBad) {
        toDisplay = component.querySelector('.count-good-weight-equal');
      }
    } else if (countBadThings > countGoodThings) {
      showElement(component.querySelector('.summary-header-more-bad'));
      if (totalWeightGood > totalWeightBad) {
        toDisplay = component.querySelector('.count-bad-weight-good');
      } else if (totalWeightBad > totalWeightGood) {
        toDisplay = component.querySelector('.count-bad-weight-bad');
      } else if (totalWeightGood == totalWeightBad) {
        toDisplay = component.querySelector('.count-bad-weight-equal');
      }
    } else if (countGoodThings == countBadThings) {
      showElement(component.querySelector('.summary-header-equal'));
      if (totalWeightGood > totalWeightBad) {
        toDisplay = component.querySelector('.count-equal-weight-good');
      } else if (totalWeightBad > totalWeightGood) {
        toDisplay = component.querySelector('.count-equal-weight-bad');
      } else if (totalWeightGood == totalWeightBad) {
        toDisplay = component.querySelector('.count-equal-weight-equal');
      }
    }
    showElement(toDisplay);

    // show total counts of pros, cons on summary page
    component.querySelector('.summary-count-good').innerHTML = countGoodThings;
    component.querySelector('.summary-count-bad').innerHTML = countBadThings;

    // copy list data to summary page lists
    finalGoodList.innerHTML = goodList.innerHTML;
    finalBadList.innerHTML = badList.innerHTML;
    // hide delete buttons on summary page lists

    var deleteButtons = component.querySelectorAll(
      '.summary-page .delete-button'
    );
    deleteButtons.forEach(deleteButton => hideElement(deleteButton));
  }

  function goBack() {
    // hide summary page (page 3) and go back to list building page (page 2)
    hideElement(summaryPage);
    showElement(listsPage);
    window.scroll(0, 0);
  }

  // button listeners

  component
    .querySelector('.intro-page-start-button')
    .addEventListener('click', goToListsPage);
  component
    .querySelector('.add-item-button--good')
    .addEventListener('click', function() {
      showModal('add', 'good');
    });
  component
    .querySelector('.add-item-button--bad')
    .addEventListener('click', function() {
      showModal('add', 'bad');
    });
  component
    .querySelector('.suggest-button--good')
    .addEventListener('click', function() {
      showModal('suggest', 'good');
    });
  component
    .querySelector('.suggest-button--bad')
    .addEventListener('click', function() {
      showModal('suggest', 'bad');
    });
  component
    .querySelector('.lists-page-continue-button')
    .addEventListener('click', goToSummaryPage);
  component.querySelector('.link-go-back').addEventListener('click', goBack);
  component
    .querySelector('.link-scroll-to-lists')
    .addEventListener('click', scrollToLists);
  component
    .querySelector('.link-scroll-to-top')
    .addEventListener('click', scrollToTop);
  component
    .querySelector('.new-list-item-cancel-button')
    .addEventListener('click', hideModal);

  // modal handling

  function showModal(purpose, topic) {
    //purpose is add or suggest, topic is good or bad
    var inputBoxArea = component.querySelector('.list-item-input-box'),
      suggestionDropdownArea = component.querySelector(
        '.list-item-suggestions-box'
      );

    var suggestions, targetList;

    component.querySelectorAll('.topic').forEach(function(el) {
      el.innerHTML = topic; // good or bad in modal header
    });

    suggestions = categories[topic].suggestions;
    targetList = `${topic}-things-list`;

    if (purpose == 'add') {
      // show add functionality in modal
      showElement(inputBoxArea);
      // hide suggest functionality in modal
      hideElement(suggestionDropdownArea);
    } else if (purpose == 'suggest') {
      var suggestionSelection = component.querySelector(
        '.list-item-suggestions-select'
      );
      // initialise dropdown and populate from suggestions
      suggestionSelection.innerHTML =
        "<option selected value=''>Please choose a suggestion...</option>";
      suggestions.forEach(function(suggestion) {
        var suggestionOption = document.createElement('option');
        var suggestionText = document.createTextNode(suggestion);
        suggestionOption.appendChild(suggestionText);
        suggestionSelection.appendChild(suggestionOption);
      });
      // show suggest functionality in modal
      showElement(suggestionDropdownArea);
      // hide add functionality in modal
      hideElement(inputBoxArea);
    }

    var addButton = component.querySelector('.new-list-item-submit-button');
    addButton.onclick = function addButtonClick() {
      var listItemInputBox = component.querySelector('.list-item-input-box'),
        listItemSuggestionsBox = component.querySelector(
          '.list-item-suggestions-box'
        ),
        listItemImportanceBox = component.querySelector(
          '.list-item-importance-box'
        );
      var input;
      if (purpose == 'add') {
        input = component.querySelector('.list-item-input');
        if (input.value == '') {
          showError(listItemInputBox);
        }
      } else if (purpose == 'suggest') {
        var inputSelect = component.querySelector(
          '.list-item-suggestions-select'
        );
        input = inputSelect.options[inputSelect.selectedIndex];
        if (input.value == '') {
          showError(listItemSuggestionsBox);
        }
      }
      var importanceSelect = component.querySelector(
        '.list-item-importance-select'
      );
      var importance = importanceSelect.options[importanceSelect.selectedIndex];
      if (importance.value == '') {
        showError(listItemImportanceBox);
      }
      if (input.value != '' && importance.value != '') {
        if (typeof ga !== 'undefined' && purpose == 'add') {
          ga('send', 'event', {
            eventCategory: 'prosandcons',
            eventAction: `add-${topic}`,
            eventValue: input.value
          });
        } else if (typeof ga !== 'undefined' && purpose == 'suggest') {
          ga('send', 'event', {
            eventCategory: 'prosandcons',
            eventAction: `add-${topic}-suggestion`
          });
        }
        addItemToList(targetList, input.value, parseInt(importance.value));
        // hide error message on list page if displayed
        cancelError(component.querySelector(`.lists-page-${targetList}-area`));
        hideModal();
      }
    };

    // show modal
    component
      .querySelector('.new-list-item-modal')
      .classList.remove('c-modal-overlay--hidden');
  }

  function hideModal() {
    // reset form values to blank
    component.querySelector('.list-item-input').value = '';
    component.querySelector('.list-item-importance-select').selectedIndex = 0;
    // hide any existing error messages
    component
      .querySelectorAll(
        '.list-item-input-box, .list-item-suggestions-box, .list-item-importance-box'
      )
      .forEach(function(el) {
        cancelError(el);
      });
    component
      .querySelector('.new-list-item-modal')
      .classList.add('c-modal-overlay--hidden');
  }

  function hideElement(c) {
    c.classList.add('u-is-hidden');
  }

  function showElement(c) {
    c.classList.remove('u-is-hidden');
  }

  // error handling

  function showError(c) {
    c.classList.add('o-form-field--error');
  }

  function cancelError(c) {
    c.classList.remove('o-form-field--error');
  }
}
