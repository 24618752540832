export default class Suggestions {
  constructor() {
    this.suggestions = [
      {
        label: 'Have an extra drink-free day',
        detail:
          'Having an extra alcohol-free day each week is a simple way to cut down.'
      },
      {
        label: 'Make a lower-strength swap',
        detail:
          'Next time you drink, swap your usual beers and wines for lower-strength versions. Check the side of the bottle or can to see how strong your drinks are.'
      },
      {
        label: 'Alternate with soft drinks',
        detail:
          'Next time you drink, have a glass of water, juice or a soft drink for each alcoholic drink you have.'
      }
    ];

    this.currentlyShown = 0;
  }

  nextSuggestion() {
    this.currentlyShown++;
    if (this.currentlyShown == this.suggestions.length) {
      this.currentlyShown = 0;
    }
  }
}
