export default class Templates {
  constructor() {
    this.versions = [
      {
        level: 'POSSIBLE_DEPENDENCE',
        header: 'You are possibly dependent on alcohol',
        lede:
          'Based on your answers, you are possibly dependent on alcohol. It’s important to get medical advice about your drinking as soon as possible.',
        detail: `<p>If you are dependent on alcohol, or think you may be, it’s dangerous to reduce the amount you drink without trained supervision.</p>
      <div class="c-card c-card--bordered o-flex o-flex--2/3">
          <div class="c-card__content">
            <p class="c-card__title">Do not change your drinking habits right now. Speak to a professional and they will help you make a change safely.</p>
          </div>
        </div>
      <p>You could:</p>
      <ul>
          <li>Speak to your GP</li>
          <li>Get in touch with one of our free services and talk confidentially to our trained staff. <a href="https://www.wearewithyou.org.uk/services/" target="_blank">Find a service</a></li>
          <li>Speak to one of our trained advisors online. They can offer free and confidential advice about what to do next. <a href="https://www.wearewithyou.org.uk/help-and-advice/about-our-online-chat#open-webchat" target="_blank">Chat to us online</a>.
      </li></ul>`,
        icon: '/img/warning-triangle-red.svg'
      },
      {
        level: 'HIGH',
        header:
          'The amount you currently drink puts you at high risk of health problems',
        detail: `<p>This might come as a surprise. If you’ve been having problems in your life, you might not connect them with how much you drink.</p>
      <p>But as you get older, changes in your body mean alcohol has more of an impact on your health, your sleep and your mood.</p>
      <p>The good news is that making just one small change to your drinking habits can reduce your risk and protect your health.</p>`,
        icon: '/img/warning-triangle-red.svg',
        compare_icon: '/img/high-chart.svg',
        compare_text:
          'You currently drink more than <strong>95% of men</strong> and <strong>98% of women</strong>.<br><small>Source: <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/drugusealcoholandsmoking/datasets/adultdrinkinghabits" target="_blank">ONS</a></small>',
        suggested_change_content: `<span class="c-drinkscheckin-tool__advice-page-header">Make changes to your drinking</span>
        <p class="u-lede">If you want to make a change for the better, looking at your drinking habits is a great place to start. </p>
        <p>This doesn’t mean stopping altogether. Even a small change can make a big difference to your health and wellbeing as you get older. </p>
        <p>You could:</p>
        <ul>
          <li><strong>Have an extra drink-free day:</strong> Having an extra alcohol-free day each week is a simple way to cut down.</li>
          <li><strong>Measure your drinks and don’t top them up:</strong> It’s easy to lose track of how much you’ve had when you drink at home. Next time, measure each drink when you pour it - and don’t top it up until it’s finished. </li>
          <li><strong>Make a lower-strength swap:</strong> Next time you drink, swap your usual beers and wines for lower-strength versions. Check the side of the bottle or can to see how strong your drinks are.</li>
          <li><strong>Alternate with soft drinks:</strong> Next time you drink, have a glass of water, juice or a soft drink for each alcoholic drink you have.</li>
        </ul>
        <p>You could also look at <a href="https://www.nhs.uk/live-well/alcohol-support/tips-on-cutting-down-alcohol/" target="_blank">advice from the NHS on cutting down how much you drink</a>.</p>`
      },
      {
        level: 'INCREASING',
        header:
          'The amount you currently drink increases your risk of health problems',
        detail: `<p>As you get older, changes in your body mean alcohol has more of an impact on your health, your sleep and your mood.</p>
        <p>But making just one small change to your drinking habits can reduce your risk and protect your health.</p>`,
        icon: '/img/warning-triangle-yellow.svg',
        compare_icon: '/img/chart-1.svg',
        compare_text:
          'You currently drink more than <strong>71% of men</strong> and <strong>85% of women</strong>.<br><small>Source: <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/drugusealcoholandsmoking/datasets/adultdrinkinghabits" target="_blank">ONS</a></small>',
        suggested_change_content: `<span class="c-drinkscheckin-tool__advice-page-header">Make changes to your drinking</span>
        <p class="u-lede">If you want to make a change for the better, looking at your drinking habits is a great place to start. </p>
        <p>This doesn’t mean stopping altogether. Even a small change can make a big difference to your health and wellbeing as you get older. </p>
        <p>You could:</p>
        <ul>
          <li><strong>Have an extra drink-free day:</strong> Having an extra alcohol-free day each week is a simple way to cut down.</li>
          <li><strong>Measure your drinks and don’t top them up:</strong> It’s easy to lose track of how much you’ve had when you drink at home. Next time, measure each drink when you pour it - and don’t top it up until it’s finished. </li>
          <li><strong>Make a lower-strength swap:</strong> Next time you drink, swap your usual beers and wines for lower-strength versions. Check the side of the bottle or can to see how strong your drinks are.</li>
          <li><strong>Alternate with soft drinks:</strong> Next time you drink, have a glass of water, juice or a soft drink for each alcoholic drink you have.</li>
        </ul>
        <p>You could also look at <a href="https://www.nhs.uk/live-well/alcohol-support/tips-on-cutting-down-alcohol/" target="_blank">advice from the NHS on cutting down how much you drink</a>.</p>`
      },
      {
        level: 'LOW',
        header:
          'The amount you currently drink is unlikely to harm your health',
        detail: `<p>This is a really good place to be. Keep it up!</p>
        <p>By drinking at this level, you’re unlikely to experience alcohol-related health problems.</p>
        <p>But as we get older, alcohol has more of an impact - so it’s important to keep up good habits and look after yourself.</p>`,
        icon: '/img/check-circle.svg',
        compare_icon: '',
        compare_text: '',
        suggested_change_content: `<span class="c-drinkscheckin-tool__advice-page-header">Keep your risk low</span>
        <p class="u-lede">The amount you currently drink is unlikely to harm your health, but it’s important to keep an eye on your drinking. </p>
        <p>Because your body processes alcohol more slowly as you get older, even sticking with your current drinking habits could cause problems in the future.</p>
        <p>For that reason, it’s helpful to get into some good drinking habits now. Even a small change today can help keep your risk low and protect your health as you get older.</p>
        <p>You could:</p>
        <ul>
          <li><strong>Avoid drinking on back-to-back days:</strong> It takes longer to process alcohol as we get older, so giving yourself a day off after you drink will give your body time to recover.</li>
          <li><strong>Measure your drinks and don’t top them up:</strong> It’s easy to lose track of how much you’re having when you drink at home. Next time, measure your drinks when you pour them - and don’t top them up before they’re finished.</li>
          <li><strong>Make a lower-strength swap:</strong> Next time you drink, swap your usual beer or wine for lower-strength versions. Check the side of the bottle or can to see how strong your drinks are.</li>
          <li><strong>Alternate with soft drinks:</strong> Next time you drink, have a glass of water, juice or a soft drink for each alcoholic drink you have.</li>
        </ul>`
      }
    ];
  }
}
