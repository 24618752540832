var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/opt/build/repo/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["src/site/drinkscheckin/questionform.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<legend>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "question")),"label"), env.opts.autoescape);
output += "<br/>\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "question")),"sub")) {
output += "\n    <span class=\"c-drinkscheckin-tool__form-sublegend\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "question")),"sub"), env.opts.autoescape);
output += "</span>\n  ";
;
}
output += "\n</legend>\n<fieldset>\n";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "question")),"options");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("opt", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n  <div class=\"o-form-field o-form-field--radio\">\n    <input type=\"radio\" id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prefix"), env.opts.autoescape);
output += "-question-";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "\" name=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prefix"), env.opts.autoescape);
output += "-question\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"value") === runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "question")),"selected")?"checked":""), env.opts.autoescape);
output += ">\n     <label class=\"o-radio-label\" for=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prefix"), env.opts.autoescape);
output += "-question-";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"label"), env.opts.autoescape);
output += "</label>\n  </div>\n  ";
if(runtime.memberLookup((t_4),"detail")) {
output += "\n    <div class=\"c-drinkscheckin-tool__radio-detail c-inset-text u-is-hidden\">\n      <div class=\"c-card c-card-flat\">\n        <div class=\"c-card__content\">\n          <p class=\"c-card__title no-margin\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"detail"), env.opts.autoescape);
output += "</p>\n        </div>\n      </div>\n    </div>\n  ";
;
}
output += "\n";
;
}
}
frame = frame.pop();
output += "\n<span class=\"o-form-field__error-message\">Choose an option to continue</span>\n</fieldset>\n<div class=\"o-form-actions\">\n  <button type=\"submit\" class=\"c-button c-button--green\">Continue</button>\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["src/site/drinkscheckin/questionform.njk"] , dependencies)